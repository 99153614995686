import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


const IndexPage = ({data}) => (
  <Layout>
    <Seo title="Resources & Events" />
    <div className="blueBG">
      <Container className="singleCol">
        <h1>Resources & Events</h1>     
      </Container>
    </div>
    <Container className="singleCol two-rem-padding">
      <Row className="g-3">
        <Col lg={6}>
          <h2>Resources</h2>

          <div key={data.resources.id}
            
            dangerouslySetInnerHTML={{ __html: data.resources.html }}
          />

        </Col>
        <Col lg={6}>
          <h2>Events</h2>

          <div key={data.events.id}
            dangerouslySetInnerHTML={{ __html: data.events.html }}
          />

        </Col>
        <Col lg={12}>
          <h2>News Articles</h2>
          <div key={data.news.id}
            className="no-bullet"
            dangerouslySetInnerHTML={{ __html: data.news.html }}
          />
        </Col>
      </Row>
      
    </Container>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    resources: markdownRemark(frontmatter: {title: {eq: "Resources"}}) {
      id
      html
      frontmatter {
        title
      }
    }
    events: markdownRemark(frontmatter: {title: {eq: "Events"}}) {
      id
      html
      frontmatter {
        title
      }
    }
    news: markdownRemark(frontmatter: {title: {eq: "News"}}) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`


